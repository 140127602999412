import React from "react"
import Portfolio from "../components/Portfolio/Portfolio"
import * as styles from "./global.module.scss"
import { Helmet } from "react-helmet"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

export default function PortfolioPage() {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - Portfolio</title>
        <meta
          name="description"
          content="Our achievements so far, with photos"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.portfolioHomepage}>
        <Portfolio />
      </div>
    </div>
  )
}
