// extracted by mini-css-extract-plugin
export var frame = "portfolio-module--frame--ENXx1";
export var content = "portfolio-module--content--uU_--";
export var imageContainer = "portfolio-module--imageContainer--vSqLM";
export var image = "portfolio-module--image--1XevS";
export var arrow = "portfolio-module--arrow--2BgwN";
export var textContainer = "portfolio-module--textContainer--2WIcq";
export var margin = "portfolio-module--margin--9Deh2";
export var detailsContainer = "portfolio-module--detailsContainer--3Nk7j";
export var fontDinproBold = "portfolio-module--fontDinproBold--2S0UG";
export var fontDinproMedium = "portfolio-module--fontDinproMedium--188W3";
export var fontLatoMedium = "portfolio-module--fontLatoMedium--2wrfJ";
export var blueText = "portfolio-module--blueText--1S_LT";
export var greyText = "portfolio-module--greyText--1YR9p";
export var darkGreyText = "portfolio-module--darkGreyText--1vpkX";
export var fontBig = "portfolio-module--fontBig--1dQX5";
export var fontSizeSmall = "portfolio-module--fontSizeSmall--8bl9b";
export var gatsbyImageWrapper = "portfolio-module--gatsby-image-wrapper--3S_5m";