import React from "react"
import Header from "../Header/Header"
import PortfolioNotClicked from "./PortfolioNotClicked/PortfolioNotClicked"
import * as styles from "./portfolio.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../Homepage/Footer/Footer"
import SidebarMenu from "../Sidebar/SidebarMenu"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "PortfolioProjects" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
    image: file(relativePath: { eq: "Hero/hero_portfolio.webp" }) {
      childImageSharp {
        fluid(maxWidth: 9500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Portfolio = () => {
  const { allFile, image } = useStaticQuery(getImage)
  const getImageByName = name => {
    let image = null
    image = allFile.edges.find(
      file => file.node.childImageSharp.fluid.originalName === name
    )
    return image
  }
  const intl = useIntl()

  return (
    <div className={styles.portfolioHomepage}>
      <SidebarMenu name="PORTFOLIO"></SidebarMenu>
      <div>
        <Header image={image.childImageSharp.fluid} pageName="PORTFOLIO" />
        <div className={styles.portfolios}>
          <PortfolioNotClicked
            image={
              getImageByName("principala_5-axis.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.short",
            })}
            redirectUrl="aviation-5-axis-machine"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_aviation-paint.webp").node
                .childImageSharp.fluid
            }
            title={intl.formatMessage({
              id: "portfolio.aviation-paint-stripping-booth.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.aviation-paint-stripping-booth.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.aviation-paint-stripping-booth.short",
            })}
            redirectUrl="aviation-paint-stripping-booth"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_cincu.webp").node.childImageSharp.fluid
            }
            title={intl.formatMessage({
              id: "portfolio.cincu-stone-and-top-soil.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.cincu-stone-and-top-soil.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.cincu-stone-and-top-soil.short",
            })}
            redirectUrl="cincu-stone-and-top-soil"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_collapsible.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.collapsible-wardrobe.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.collapsible-wardrobe.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.collapsible-wardrobe.short",
            })}
            redirectUrl="collapsible-wardrobe"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_cooling.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({ id: "portfolio.cooling-fans.title" })}
            subtitle={intl.formatMessage({
              id: "portfolio.cooling-fans.service",
            })}
            text={intl.formatMessage({ id: "portfolio.cooling-fans.short" })}
            redirectUrl="cooling-fans"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_heating.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.heating-ventilation-and-air-conditioning.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.heating-ventilation-and-air-conditioning.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.heating-ventilation-and-air-conditioning.short",
            })}
            redirectUrl="heating-ventilation-and-air-conditioning"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_matting.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.matting-foundation-and-walkways.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.matting-foundation-and-walkways.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.matting-foundation-and-walkways.short",
            })}
            redirectUrl="matting-foundation-and-walkways"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_lockers.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.mechanics-lockers.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.mechanics-lockers.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.mechanics-lockers.short",
            })}
            redirectUrl="mechanics-lockers"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_point-solar.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.short",
            })}
            redirectUrl="fuel-point-solar-lightning"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_modular.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.modular-wash-rack.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.modular-wash-rack.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.modular-wash-rack.short",
            })}
            redirectUrl="modular-wash-rack"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_shipping.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.shipping-containers.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.shipping-containers.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.shipping-containers.short",
            })}
            redirectUrl="shipping-containers"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_shower.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.shower-container.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.shower-container.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.shower-container.short",
            })}
            redirectUrl="shower-container"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_storage.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.storage-containers.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.storage-containers.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.storage-containers.short",
            })}
            redirectUrl="storage-containers"
          />

          <PortfolioNotClicked
            title={intl.formatMessage({ id: "portfolio.rampa.title" })}
            image={
              getImageByName("principala_rampa.webp").node.childImageSharp.fluid
            }
            subtitle={intl.formatMessage({ id: "portfolio.rampa.service" })}
            text={intl.formatMessage({ id: "portfolio.rampa.short" })}
            redirectUrl="rampa"
          />

          <PortfolioNotClicked
            image={
              getImageByName("principala_water-jet.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.aviation-water-jet-machine.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.aviation-water-jet-machine.service",
            })}
            text={intl.formatMessage({
              id: "portfolio.aviation-water-jet-machine.short",
            })}
            redirectUrl="aviation-water-jet-machine"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Portfolio
