import React from "react"
import * as styles from "./portfolio.module.scss"
import Image from "gatsby-image"
import arrow from "../../../images/Portfolio/arrow.svg"
import { useIntl, Link } from "gatsby-plugin-intl"

const PortfolioNotClicked = ({ title, subtitle, text, redirectUrl, image }) => {
  const intl = useIntl()
  return (
    <div className={styles.frame}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image fluid={image} className={styles.image} alt="Portfolio Image" />
        </div>

        <div className={styles.textContainer}>
          <div className={[styles.fontDinproBold, styles.blueText].join(" ")}>
            {" "}
            {title}{" "}
          </div>
          <div
            className={[
              styles.fontDinproBold,
              styles.fontSizeSmall,
              styles.greyText,
            ].join(" ")}
          >
            {" "}
            {subtitle}{" "}
          </div>
          <div
            className={[
              styles.fontLatoMedium,
              styles.darkGreyText,
              styles.margin,
            ].join(" ")}
          >
            {" "}
            {text}{" "}
          </div>
          <Link
            to={"/home/portfolio/" + redirectUrl}
            className={styles.detailsContainer}
          >
            <div
              className={[styles.fontDinproMedium, styles.blueText].join(" ")}
            >
              {intl.formatMessage({ id: "Details" })}
            </div>
            <img src={arrow} alt="Details Arrow" className={styles.arrow} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PortfolioNotClicked
